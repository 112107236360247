import React from "react";
import GoogleMap from "../../components/GoogleMap";

import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaHeart } from "react-icons/fa";

import {
  FooterUpperWrapper,
  FooterLowerWrapper,
  Copyright
} from "./styles";

import logo from "../../assets/marca_gestpar_white.svg";
import coin from "../../assets/icons/icon_coin.svg";
import hand from "../../assets/icons/icon_hand.svg";
import head from "../../assets/icons/icon_head.svg";

export default function Footer() {
 
  return (
    <>
      <FooterUpperWrapper>
        <h3>Na Gestpar você tem</h3>
        <div className="icons">
          <div className="item">
            <img src={coin} alt="Economia" />
            <h6>Economia</h6>
            <p>
              Projetos personalizados de acordo com as reais necessidades de
              cada cliente.
            </p>
          </div>
          <div className="item">
            <img src={hand} alt="Economia" />
            <h6>Confiabilidade</h6>
            <p>
              Processos protegidos com vários níveis de segurança e
              funcionalidade.
            </p>
          </div>
          <div className="item">
            <img src={head} alt="Economia" />
            <h6>Soluções inteligentes</h6>
            <p>Reengenharia de processos e fluxos de trabalho otimizados.</p>
          </div>
        </div>
      </FooterUpperWrapper>
      <GoogleMap></GoogleMap>
      <FooterLowerWrapper>
        <div className="menuWrapper">
          <div className="socials">
            <a
              href="https://www.facebook.com/Gestpar-Gestão-Inteligente-de-Documentos-105948350772748"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebookF size={55} />
            </a>
            <a
              href="https://www.instagram.com/explore/locations/1208795242465827/gestpar-copiadoras-e-sistemas-de-impressao/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram size={55} />
            </a>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/sobre">Sobre</Link>
            </li>
            <li>
              <Link to="/servicos">Serviços</Link>
            </li>
            <li>
              <Link to="/equipamentos">Equipamentos</Link>
            </li>
            <li>
              <Link to="/contato">Contato</Link>
            </li>
            <li>
              <Link to="/suporte">Suporte</Link>
            </li>
            <li>
              <Link to="/downloads">Downloads</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        <div className="logoWrapper">
          <div className="block">
            <Link to="/">
              <img src={logo} alt="Gestpar" />
            </Link>
          </div>
        </div>
        <div className="infoWrapper">
          {/* <div> */}

          <a href="https://goo.gl/maps/taHhUvp7baLqmqC56" target="_blank">
            <span>R. Brasil, 262 - Oficinas <br />
              Ponta Grossa - PR, 84036-010
            </span>
          </a><br/>

          <span>(42) 3026-2222</span>

          <span>
            informacoes@gestpar.com.br
          </span>
          {/* </div> */}
        </div>
      </FooterLowerWrapper>
      <Copyright>
        <span>
          Desenvolvido com <FaHeart size={10} color="#D0021B" /> por{" "}
          <a
            href="https://agenciaade.com.br"
            target="_blank"
          >
            Agência Ade!
          </a>
        </span>
      </Copyright>
    </>
  );
}
