import React from 'react'
import TagManager from 'react-gtm-module'
import { BrowserRouter, HashRouter } from 'react-router-dom'

import Routes from './routes/routes'
import GlobalStyle from './styles/global'

import Footer from './components/Footer'
import Header from './components/Header'

const Router =
  process.env.REACT_APP_SERVER_ENV === 'development'
    ? HashRouter
    : BrowserRouter

const tagManagerArgs = {
  gtmId: 'GTM-KKQ2DBLL',
}

// Iniciar o GTM
TagManager.initialize(tagManagerArgs)

const App = () => (
  <Router>
    <GlobalStyle />
    <Header />
    <Routes />
    <Footer />
  </Router>
)

export default App
